import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes';
import "./_scss/tripperbus.scss";
import ReactGA from "react-ga4";
import TagManager from 'react-gtm-module'
import * as Sentry from "@sentry/react";


Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllInputs: false,
      maskAllText:false,

    }),
  ],
  tracesSampleRate: 1.0, 
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV,

});

const handleUtmCookie = () => {
  try{
    var rootDomain = process.env.REACT_APP_HOME_DOMAIN_URL;
  rootDomain = rootDomain.replace(/(^\w+:|^)\/\//, '').replace(/\/$/, '');
  rootDomain = rootDomain.replace(/:\d+$/, '');
  rootDomain = rootDomain.split('.').slice(-2).join('.');
  const getUtmParamsFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmParams = {
      utm: "",
      utm_campaign: ""
    };
    urlParams.forEach((value, key) => {
      if (key.startsWith('utm_')) {
        if (utmParams.utm === "") {
          utmParams.utm = value;
        } else {
          utmParams.utm += `_${value}`;
        }
        if (key === "utm_campaign") {
          utmParams.utm_campaign = value;
        }
      }
    });
    return utmParams;
  };
  const maxage = 30 * 24 * 60 * 60;
  var utm = getUtmParamsFromUrl();
  if(utm.utm !== ""){
    document.cookie = `utm=${JSON.stringify(utm)}; domain=.${rootDomain}; path=/; max-age=${maxage}; SameSite=None; Secure`;
  }
  }catch(e){
  }
};

handleUtmCookie();
 
const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID
}
const tagManagerArgs2 = {
  gtmId: process.env.REACT_APP_GTM_ID_2
}
 
ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID);
TagManager.initialize(tagManagerArgs)
TagManager.initialize(tagManagerArgs2)


ReactDOM.render(
  <Routes />,
  document.getElementById('page-top')
);
